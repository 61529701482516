@import 'src/utils/utils';

.layout {
  margin-top: 25px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 25px;

  @media(min-width: $lg) {
    margin-top: 30px;
    margin-right: 0;
    margin-bottom: 30px;
    margin-left: 0;
  }
  @media(min-width: $xl) {
    @include box;
  }
  
  &--square {
    display: grid;
    gap: 20px;

    @media(min-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media(min-width: $lg) {
      grid-template-columns: 1fr;
    }
    @media(min-width: $xl) {
      grid-template-columns: 1fr 1fr;
    }

    .current {
      margin-right: -16px;
      margin-bottom: 0;
      margin-left: -16px;
  
      @media(min-width: $md) {
        margin-right: 0;
        margin-left: 0;
      }

      &__meta {
        padding-right: 0;

        @media(min-width: $lg) {
          display: flex;
          flex-wrap: wrap;
        }
      }

      &__category {
        width: 100%;
      }

      &__footer {
        display: flex;
        flex-wrap: wrap;
      }

      &__views {
        width: 100%;
      }
    }
  }

  &--lines {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid $Main-5;
    border-bottom: 1px solid $Main-5;
    row-gap: 25px;

    & > div,
    & > section {
      margin: 0;
    }
  }

  &--line {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    border-top: 1px solid $Main-5;
    row-gap: 25px;

    @media(min-width: $lg) {
      margin-bottom: 30px;
      padding-top: 30px;
    }

    & > div,
    & > section {
      margin: 0;
    }
  }
}